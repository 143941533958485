<template>
  <nav aria-label="分页">
    <ul class="pagination">
      <!-- Previous Page -->
      <li :class="{ disabled: currentPage === 1 }"
        class="page-item"
      >
        <NuxtLink :to="linkTo(generatePageLink(currentPage - 1))"
          class="page-link"
          @click="handlePageClick(currentPage - 1)"
        >
          上一页
        </NuxtLink>
      </li>

      <!-- Pages -->
      <li v-for="page in displayPages"
        :key="page"
        :class="{ active: currentPage === page, ellipsis: page === '...' }"
        class="page-item"
      >
        <NuxtLink v-if="page !== '...'"
          :to="linkTo(generatePageLink(page))"
          class="page-link"
          @click="handlePageClick(page)"
        >
          {{ page }}
        </NuxtLink>
        <span v-else class="page-link">...</span>
      </li>

      <!-- Next Page -->
      <li :class="{ disabled: currentPage === totalPages }"
        class="page-item"
      >
        <NuxtLink :to="linkTo(generatePageLink(currentPage + 1))"
          class="page-link"
          @click="handlePageClick(currentPage + 1)"
        >
          下一页
        </NuxtLink>
      </li>
    </ul>
  </nav>
</template>

<script setup>
// 返回页码了就展示分页组件
const route = useRoute();
const props = defineProps({
  total: {
    type: Number,
    required: true,
  },
  pageSize: {
    type: Number,
    default: 20,
  }
});

const emit = defineEmits(['update:currentPage']);
const currentPage = ref(Number(route.params.page) || 1);

const totalPages = computed(() => Math.ceil(props.total / props.pageSize));

const generatePageLink = (page) => {
  const routePath = route.path.replace(/\/$/, ''); // 去掉路径末尾的斜杠
  const regex = /\/\d+$/; // 匹配路径中最后一个斜杠后的数字部分

  if (route.params.page && regex.test(routePath)) {
    // 如果匹配到数字部分，替换为新的数字
    return page < 1 || page > totalPages.value
      ? routePath.replace(regex, '')
      : routePath.replace(regex, `/${page}`);
  } else {
    // 如果没有匹配到数字部分，统一在最后追加页码
    return page < 1 || page > totalPages.value
      ? routePath
      : `${routePath}/${page}`;
  }
};



const pages = computed(() => Array.from({ length: totalPages.value }, (_, i) => i + 1));

// 计算显示的页码
const displayPages = computed(() => {
  const pagesArray = pages.value;
  const maxPagesToShow = 7;
  const current = currentPage.value;

  if (totalPages.value <= maxPagesToShow) {
    return pagesArray;
  }

  if (current <= 5) {
    return [...pagesArray.slice(0, 5), '...', totalPages.value];
  }

  if (current > totalPages.value - 5) {
    return [1, '...', ...pagesArray.slice(totalPages.value - 5)];
  }

  return [1, '...', current - 1, current, current + 1, '...', totalPages.value];
});

const handlePageClick = (page) => {
  if (page === '...') return;
  if (page < 1 || page > totalPages.value) return;

  currentPage.value = page;
  emit('update:currentPage', page);
};
</script>

<style scoped>
.pagination {
  display: flex;
  list-style: none;
  padding: 0;
}

.page-item {
  margin-right: var(--pagination-item-mr);
}

.page-link {
  cursor: pointer;
  text-decoration: none;
  padding: var(--pagination-link-p);
  font-size: 14px;
  background-color: var(--search-bg-color);
  color: var(--title-color);
  border-radius: 2px;
  display: block;
}

.page-item.active .page-link {
  font-weight: bold;
  color: #fff;
  background-color: var(--primary-color);

}

.page-item.disabled .page-link {
  color: #ccc;
  pointer-events: none;
  cursor: not-allowed;
  opacity: 0.8;
}

.page-item.ellipsis .page-link {
  cursor: default;
}
</style>
